import React from 'react'
import ProjectPage from '../page-templates/ProjectPage/ProjectPage.connected'
import Page from '../page-templates/Page/Page.connected'
import { Draft } from '@kogk/gatsby-theme-prismic'

const typeMap = {
  page: Page,
  project: ProjectPage
}

export default () => <Draft typeMap={typeMap} />
